<template lang="pug">
.image-input(:class="[disable ? 'disabled-image-input' : '']")
    span {{ text }}
    div(v-if="!hideDelete" )
        img(src="@/assets/images/plus-circle.svg" v-if="!imageName" @click="openUpload")
        img.close(src="@/assets/images/close.png" @click="deleteImage" v-else)
        span(@click="openUpload") {{ imageName || text2 }}
    div(v-else)
        img(src="@/assets/images/plus-circle.svg")
        span(@click="openUpload") {{ text2 }}
    input(ref="input" type="file" @change="changedImage" accept="image/*")
</template>

<script>
export default {
    name: "DefaultImageInput",
    props: {
        text: {
            type: String,
            default: ''
        },
        text2: {
            type: String,
            default: ''
        },
        hideDelete: {
            type: Boolean,
            default: false
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            imageName: null,
            imageSrc: []
        }
    },
    methods: {
        openUpload() {
            if (!this.disable) {
                if (this.imageName) {
                    this.deleteImage()
                } else {
                    this.$refs.input.click()
                }
            }
        },
        changedImage(e) {
            let reader = new FileReader();
            const image = e.target.files[0];
            this.imageName = image.name

            reader.onload = (event) => {
                this.imageSrc.push(event.target.result)
            }
            reader.readAsDataURL(image);
            this.$emit("update:value", {imageFile: image, imageSrc: this.imageSrc})
        },
        deleteImage() {
            this.imageName = null
            this.imageSrc = []
            this.$emit("update:value", null)
        }
    }
}
</script>

<style lang="scss" scoped>
.image-input {
    user-select: none;

    span {
        display: block;
        color: #434242;
        font-size: 12px;
        padding-left: 4px;
    }

    & > span {
        margin-bottom: 8px;
    }

    & > div {
        cursor: pointer;
        display: flex;
        align-items: center;

        img.close {
            width: 14px;
        }

        span {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    & > input {
        display: none;
    }

}

.image-input.disabled-image-input {
    opacity: .5;
    pointer-events: none;
}
</style>

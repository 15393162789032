<template lang="pug">
.company-dashboard
	.company-posts
		h2 ᲩᲔᲛᲘ ᲓᲐᲓᲔᲑᲣᲚᲘ ᲞᲝᲡᲢᲔᲑᲘ
		.company-posts-listing
			.company-post(v-if="myPosts.results && myPosts.results.length" v-for="post in myPosts.results"
				:key="post.id")
				.company-post-head
					.post-user
						img(:src="post.user.profile_image !== null ? post.user.profile_image : defaultImage")
						span {{ post.user.first_name + " " + post.user.last_name }}
					.post-interests
						span {{ post.interests }}
						img(src="@/assets/images/Icon-ionic-ios-heart-empty-black.svg")
				.company-post-description(@click="redirect(post)")
					p {{ post.description }}
			h4(v-else) დადებული პოსტები არ გაქვთ
	.personal-data
		h2 ᲞᲘᲠᲐᲓᲘ ᲛᲝᲜᲐᲪᲔᲛᲔᲑᲘ
		.personal-data-main
			.first-row
				.company-image(v-if="userProfileImage" )
					img(:src="userProfileImage")
					//DefaultImageInput(text2="ფოტოს ატვირთვა" v-model:value="imageObject")
				.company-info
					DefaultInput(text="კომპანია" placeholder="კომპანიის სახელი" value="კომპანიის სახელი")
					DefaultInput(text="საიდენტიფიკაციო კოდი" placeholder="0123346527364637463745" value="0123346527364637463745")
			.second-row
				DefaultInput(text="ტელეფონი" value="555 55 55 55")
				DefaultInput(text="მეილი" value="username@email.com")
			DefaultTextarea(text="აღწერა" placeholder-text="" v-model:value="description" )
</template>

<script>
import {mapGetters} from "vuex";

import DefaultImageInput from "../components/layout/DefaultImageInput";
import DefaultInput from "../components/layout/DefaultInput";
import DefaultTextarea from "../components/layout/DefaultTextarea";
import defaultImage from "../assets/images/defaultImage.png"

export default {
	name: "MyPosts",
	components: {
		DefaultTextarea,
		DefaultInput,
		DefaultImageInput
	},
	data() {
		return {
			description: "აღწერის ტექსტი აღწერის ტექსტიაღწერის ტექსტი",
			imageObject: {
				imageSrc: ''
			}
		}
	},
	computed: {
		...mapGetters({
			myPosts: "posts/myPosts",
			userProfileImage: "user/userProfileImage"
		}),
		defaultImage() {
			return defaultImage
		}
	},
	mounted() {
		this.$store.dispatch('posts/fetchMyPosts')
		this.$store.dispatch('user/userData')
	},
	methods: {
		redirect({is_published, id}) {
			if (is_published) this.$router.push({name: 'post-detail', params: {postId: id}})
			else this.$router.push({name: 'edit-post', params: {postId: id}})
		}
	}
}
</script>

<style lang="scss" scoped>
.company-dashboard {
	min-height: calc(100vh - 108px);
	padding-top: 37px;
	display: grid;
	grid-template-columns: 3fr 2fr;
	grid-column-gap: 30px;

	h2 {
		font: normal normal bold 16px/19px 'Helvetica-Regular';
		letter-spacing: 0;
		color: #212121;
		margin-bottom: 19px;
	}

	.company-posts {
		display: flex;
		flex-direction: column;

		.company-posts-listing {
			height: 100%;
			max-height: calc(100vh - 183px);
			overflow: auto;
			background: #FFFFFF 0 0 no-repeat padding-box;
			box-shadow: 0 3px 20px #00000029;
			border-radius: 5px;
			padding: 14px 22px;

			.company-post {
				margin-bottom: 10px;
				border-bottom: 1px solid #DDDDDD;

				:last-child {
					margin-bottom: 0;
				}

				.company-post-head {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.post-user {
						display: flex;
						align-items: center;
						gap: 5px;

						img {
							width: 22px;
							height: 22px;
							border-radius: 50%;
						}

						span {
							font: normal normal normal 14px/17px 'Helvetica-Regular';
							letter-spacing: 0;
							color: #212121;
						}
					}

					.post-interests {
						display: flex;
						align-items: center;
						gap: 4px;

						span {
							font: normal normal normal 12px/14px 'Helvetica-Regular';
							letter-spacing: 0;
							color: #6E6E6E;
						}

						img {
							width: 16px;
							height: 13px;
						}
					}
				}

				.company-post-description {
					margin: 12px 0 23px;

					p {
						font: normal normal normal 14px/17px 'Helvetica-Regular';
						letter-spacing: 0;
						color: #6E6E6E;
						cursor: pointer;
					}
				}
			}

			.company-post:last-child {
				margin-bottom: 0;
			}

			> h4 {
				color: #212121;
			}
		}

		.company-posts-listing::-webkit-scrollbar {
			width: 5px;
			padding-right: 14px;
		}

		/* Track */
		.company-posts-listing::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		.company-posts-listing::-webkit-scrollbar-thumb {
			background: #E0DDDD;
			border-radius: 10px;
			transition: .3s;
		}

		.company-posts-listing::-webkit-scrollbar-thumb:hover {
			background: #888;
		}
	}

	.personal-data {
		display: flex;
		flex-direction: column;

		.personal-data-main {
			height: 100%;
			background: #FFFFFF 0 0 no-repeat padding-box;
			box-shadow: 0 3px 20px #00000029;
			border-radius: 5px;
			padding: 23px 30px;

			> .first-row {
				display: flex;
				gap: 28px;

				.company-image {

					img {
						width: 149px;
						height: 165px;
						border-radius: 10px;
						object-fit: cover;
					}
				}

				.company-info {
					width: 100%;

					:deep(.default-input:nth-child(1)) {
						margin-bottom: 20px;
					}
				}
			}

			> .second-row {
				margin: 35px 0 50px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			// DefaultTextarea Styles
			:deep(.default-textarea) {
				span {
					font: normal normal normal 12px/13px 'Helvetica-Regular';
					letter-spacing: 0;
					color: #434242;
					padding: 0;
				}

				textarea {
					width: 100%;
					padding-left: 0;
					font: normal normal bold 12px/13px Helvetica;
					letter-spacing: 0;
					color: #434242;
				}
			}

			// DefaultInput Styles
			:deep(.default-input) {
				> span {
					font: normal normal normal 12px/13px 'Helvetica-Regular';
					letter-spacing: 0;
					color: #434242;
					padding: 0;
				}

				.input-wrapper {
					input {
						font: normal normal bold 12px/13px 'Helvetica-Regular';
						letter-spacing: 0;
						color: #434242;
						padding: 0;
					}

					input::placeholder {
						color: #434242;
					}
				}
			}
		}
	}
}
</style>
